import { Component, OnInit } from '@angular/core';
import { social } from '@shared/data/media.data.json';

@Component({
  selector: 'app-social-links',
  templateUrl: './social-links.component.html',
  styleUrls: ['./social-links.component.scss']
})
export class SocialLinksComponent implements OnInit {

  socialLinks: any[];
  constructor() {
    this.socialLinks = social;
  }

  ngOnInit(): void {
  }

}
