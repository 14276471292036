import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit, OnChanges {

  @Input('pageName') pageName: string = '';
  standalone: boolean;

  constructor(private appService: AppService) { }
  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
    this.standalone = this.appService.isStandalone;
  }

}
