import { Component } from '@angular/core';
import * as COMPANY_DATA from '@data/company.data.json';
import { SplashModel } from '@models/splash.model'
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})

export class SplashComponent {

  home: string;
  projectName: string;
  DEFAULT_TEXT: string;
  splashData: SplashModel
  splashImage: string = `url(${COMPANY_DATA.splashImage})`;

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.projectName = this.appService.app.name;
    this.home = this.appService.home;
    this.DEFAULT_TEXT = `The ${this.projectName} Project visual simulations have been produced using the most current and accurate photography, 3d modelling, survey, and terrain data available. While these simulations show the most likely level of visual impact from each observation point, changes to the final site layout, vegetation screening or facility specifications may result in different visual effects once the actual project is constructed.`;

    this.splashData = {
      "splashText": this.DEFAULT_TEXT,
      "splashImage": this.splashImage
    };
  }
}