import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { imageOverlay, geoJSON, Layer, Polyline } from 'leaflet';
import { map } from '@shared/data/media.data.json';
/**
 * Service for managing Leaflet map layers. Assumes layer data is in "shared/data/data.json" in the following format:
 * "map": {
    "layers": [
      {
        "title": "Proposed",
        "slug": "proposed",
        "fileName": "proposed.png",
        "type": "image",
        "coords": {
          "topLeft": [30.888525, -86.218425],
          "bottomRight": [30.857850, -86.196022]
        }
      }
    ]
  }
 */
@Injectable({
  providedIn: 'root',
})
export class LayersService {
  private overlays: any;
  private IMAGE_PATH = 'assets/map/layers/';

  constructor(private http: HttpClient) {
    this.createOverlays().then((overlays) => {
      this.overlays = overlays;
    });
  }

  /**
   * Create Leaflet overlays from data from value of "map" property in "shared/data/data.json".
   * @returns Leaflet overlays object
   */
  createOverlays() {
    return new Promise(async (resolve) => {
      let overlays = {};
      for (let i = 0; i < map.layers.length; i++) {
        const layer = map.layers[i];
        overlays[layer.title] = await this.getLeafletLayer(layer);
      }
      resolve(overlays);
    });
  }

  /**
   * Get Leaflet overlay from layer object in "map" property in "shared/data/data.json".
   * @returns Leaflet overlay object
   */
  async getLeafletLayer(layerData) {
    let layer: Layer = null;
    if (layerData.type === 'image') {
      layer = imageOverlay(`${this.IMAGE_PATH}${layerData.fileName}`, [
        layerData.coords.topLeft,
        layerData.coords.bottomRight,
      ]);
    }
    // NOTE: performance may be an issue with large GeoJSON files and it may be better to use an 
    // optimised png image file instead
    if (layerData.type === 'geojson') {
      const layerGeoJSONData = await this.getGeoJSONData(layerData.slug);
      layer = geoJSON(layerGeoJSONData as any, {
        onEachFeature: (feature, layer) => {
          if (layer instanceof Polyline) {
            layer.setStyle(layerData.style);
            if (layerData.tooltip) {
              let label = layerData.title;
              layer.bindTooltip(label, layerData.tooltip).openTooltip();
            }
          }
        },
      });
    }
    return layer;
  }

  /**
   * Get GeoJSON data from GeoJSON file in assets directory,
   * @param slug 
   * @returns 
   */
  async getGeoJSONData(slug) {
    return new Promise((resolve) => {
      return this.http
        .get(`assets/map/layers/${slug}.geo.json`)
        .subscribe((json: any) => {
          resolve(json);
        });
    });
  }

  /**
   * Create Leaflet overlays from data from value of "map" property in "shared/data/data.json".
   * @returns Leaflet overlays object
   */
  getOverlays() {
    return this.overlays;
  }
}
