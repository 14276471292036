<div
  class="viewpoint-map"
  #map
  [ngClass]="[isMinimized ? 'is-minimized' : '', isReady ? 'is-visible' : '']"
  style="height: 100vh; width: 100vw"
  leaflet
  [leafletOptions]="options"
  [leafletLayers]="markers"
  (leafletMapReady)="onMapReady($event)"
></div>
