import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StateService } from '@shared/services/state.service';
import { Viewpoint } from '@shared/models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
})
export class SwitchComponent implements OnInit {
  @Input('viewpoint') viewpoint: Viewpoint;
  @Input('checked') checked: boolean = false;
  @Input('overlayChecked') overlayChecked: boolean = false;
  @Input('vegetationChecked') vegetationChecked: boolean = true;
  @Output() check = new EventEmitter();
  @Output() checkLayer = new EventEmitter();
  toggleState: any;
  state: any;
  standalone: boolean;
  isCheckbox: boolean;
  constructor(stateService: StateService, private appService: AppService) {
    this.state = stateService.getState();
  }

  ngOnInit(): void {
    this.standalone = this.appService.isStandalone;    
    this.toggleState = {
      overlay: {
        checked: false,
      },
      vegetation: {
        checked: true,
      },
    };
    if(this.viewpoint && this.viewpoint.mode.proposed.layers)  {
      this.isCheckbox = this.viewpoint?.mode.proposed.layers.isCheckbox;}
  }

   //switch toggle button
   toggle() {
    this.checked = !this.checked;
    this.check.emit({ checked: this.checked });
  }

  toggleLayer(layerName) {        
    this.toggleState[layerName].checked = !this.toggleState[layerName].checked;
    if (layerName === 'vegetation') {
      this.toggleNonVegetationLayers();      
    }        
    this.checkLayer.emit(this.toggleState);
  }

  toggleNonVegetationLayers() {
    for (let i = 0; i < this.viewpoint.mode.proposed.layers.items.length; i++) {
      const layer = this.viewpoint?.mode.proposed.layers.items[i];
      if (layer.slug !== 'vegetation') {
        layer.isHidden = !layer.isHidden;
      }
    }
  }

  //radio button layers only
  activateLayer(layer) {
    if (this.checked) {
      this.viewpoint.mode.proposed.layers.radioItems.forEach((proposedLayer: any) => {
        proposedLayer.isActive = false
      })
    }
    layer.isActive = true
    this.checkLayer.emit(layer)
  }
}
