import {
  Component,
  OnInit,
} from '@angular/core';
import { ViewpointsService } from '@shared/services/viewpoints.service';
import 'leaflet/dist/images/marker-shadow.png';
import 'leaflet-rotatedmarker';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  mode: string = 'proposed';
  viewpoints: any[];
  viewpoint: any;
  standalone: boolean;

  constructor(
    private viewpointsService: ViewpointsService,
    private appService: AppService,
  ) { }

  ngOnInit(): void {
    this.standalone = this.appService.isStandalone;
    this.viewpoints = this.viewpointsService.getViewpoints();
    const centerViewpointSlug = this.viewpoints.find(x => x.centerViewpoint).slug
    this.viewpoint = this.viewpointsService.getViewpoint(centerViewpointSlug, false);
  }

  public toggleMode = (): void => {
    this.viewpointsService.toggleMode();
  };
}
