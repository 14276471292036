import { Component, Input, OnInit } from '@angular/core';
import { app } from '@shared/data/media.data.json';
import { Viewpoint } from '@shared/models';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-horizontal-toolbar',
  templateUrl: './horizontal-toolbar.component.html',
  styleUrls: ['./horizontal-toolbar.component.scss']
})
export class HorizontalToolbarComponent implements OnInit {
  @Input('justifyLeft') justifyLeft: boolean = false;
  @Input('toggleBtnTop') toggleBtnTop: number = 87;
  @Input('title') title: string;
  @Input('viewpoint') currentViewpoint: Viewpoint;
  @Input('viewpoints') viewpoints: Viewpoint[];
  isAB: boolean = true;
  isA: boolean = true;
  isShow: boolean = false;
  app: any;
  isMap: boolean;

  constructor(private appService: AppService) {
    this.app = app;
  }

  ngOnInit(): void {
    this.isMap = this.appService.home === "map" ? true : false
    if (!this.isMap) {
      this.viewpoints = this.viewpoints.filter(x => !x.main)
    }

  }

  toggleAB() {
    this.isA = !this.isA;
  }

  toggle() {
    this.isShow = !this.isShow;
  }

}
