import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as COMPANY_DATA from '@data/company.data.json';
import { AppService } from './app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  appType: string;
  standalone: boolean;

  constructor(private titleService: Title, private appService: AppService, private router: Router,) {
    this.appType = this.appService.appType;
    this.standalone = this.appService.isStandalone;
    this.titleService.setTitle(`${this.appService.title} | ${COMPANY_DATA.companyName}`);
  }
  ngOnInit(): void {
    this.router.navigate([""])
  }
}