import { Component, OnInit } from '@angular/core';
import { links } from '@shared/data/media.data.json';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  links: any[];
  constructor() {
    this.links = links;
  }

  ngOnInit(): void { }
}
