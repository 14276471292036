import { EventEmitter, Injectable, Output } from '@angular/core';
import { viewpoints } from '@data/viewpoint.data.json';

/**
 * Service for managing viewpoints. Assumes layer data is in "shared/data/data.json" in the following format:
 * {
  "viewpoints": [
    {
      "slug": "harrison-road-1",
      ...
    }
  ]
}
 */
@Injectable({
  providedIn: 'root',
})
export class ViewpointsService {
  viewpoints: any;
  @Output() onUpdateViewpoint = new EventEmitter();
  @Output() onChangeViewpoint = new EventEmitter();
  @Output() onChangeMode = new EventEmitter();

  constructor() {
    this.viewpoints = viewpoints;
  }

  /**
   * Gets all viewpoints
   * @returns Array viewpoints
   */
  getViewpoints() {
    return this.viewpoints;
  }

  getMainViewpoint() {
    const mainViewpoint = this.viewpoints.find(x => x.main)
    return mainViewpoint ? mainViewpoint : this.viewpoints[0];
  }

  /**
   * Gets a Viewpoint object by slug.
   * @param slug
   * @returns Object viewpoint
   */
  getViewpoint(slug, isEmitViewpoint) {
    let viewpoint = null;
    for (let i = 0; i < this.viewpoints.length; i++) {
      const v = this.viewpoints[i];
      if (v.slug === slug) {
        viewpoint = v;
        break;
      }
    }
    //emit viewpoint for header to receive
    if (isEmitViewpoint) {
      this.onChangeViewpoint.emit(viewpoint);
    } else {
      this.onChangeViewpoint.emit(null);
    }
    return viewpoint;
  }

  toggleMode() {
    //emit viewpoint for header to receive
    this.onChangeMode.emit();
  }
}
