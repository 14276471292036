import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { app } from '@shared/data/media.data.json';

@Component({
  selector: 'app-dark-mode',
  templateUrl: './dark-mode.component.html',
  styleUrls: ['./dark-mode.component.scss'],
})
export class DarkModeComponent implements OnInit {
  isDark: boolean = false;
  @Input('top') top: number = 87;
  @Output() toggleDarkScene = new EventEmitter();
  app: any;

  constructor() {
    this.app = app;
  }

  ngOnInit(): void { }

  toggle() {
    this.isDark = !this.isDark;
    this.toggleDarkScene.emit(this.isDark);
  }
}
